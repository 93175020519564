import React from 'react';
import TableRow, { TableRowCellHeader } from './TableRow';
import { TableRowPropsRow } from './TableRow';

type TableBodyProps = {
  customStyles?: { tableRowStyles?: { [key: string]: string } };
  headers: TableRowCellHeader[];
  rows: TableRowPropsRow[];
  selectedRows: string[];
  setSelectedRows: (selectedRows: string[]) => void;
  showCheckboxColumn: boolean;
};

export default function TableBody({
  customStyles = { tableRowStyles: {} },
  headers = [],
  rows = [],
  selectedRows = [],
  setSelectedRows,
  showCheckboxColumn,
}: TableBodyProps) {
  const rowsToRender = rows.map((row, index) => (
    <TableRow
      customStyles={customStyles.tableRowStyles || {}}
      headers={headers}
      row={row}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      showCheckboxCell={showCheckboxColumn}
      key={`${row.id}-${index}`}
    />
  ));
  return <tbody>{rowsToRender}</tbody>;
}
