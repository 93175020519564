import { useState } from 'react';
import { getMoralisWalletNfts } from './getStatus';
import { GetWalletGenericResponse } from '../types/SocietyLoyaltyWallet';

export default function useFetchMoralisNfts() {
  const [moralisWallet, setMoralisWallet] =
    useState<GetWalletGenericResponse>();

  function fetchMoralisWallet(address: string) {
    // console.log('useFetchMoralisNfts.fetchMoralisWallet: address', { address: address });
    getMoralisWalletNfts(address)
      .then((res) => {
        // console.log('useFetchMoralisNfts.fetchMoralisWallet: res', { res: res });
        if (!(res as { error: string }).error) {
          try {
            const response = res as GetWalletGenericResponse;
            setMoralisWallet(response);
          } catch (err) {
            // console.error(
            //   'useFetchMoralisNfts.fetchMoralisWallet: res.json() parse error',
            //   { errMsg: err.message, res, err },
            // );
            setMoralisWallet(undefined);
          }
        } else {
          console.log(
            'useFetchMoralisNfts.fetchMoralisWallet: res not ok %o',
            res,
          );
          setMoralisWallet(undefined);
        }
      })
      .catch((err) => {
        console.error('useFetchMoralisNfts.fetchMoralisWallet: error', err);
        // return Promise.reject(error);
      });
  }

  return { moralisWallet, fetchMoralisWallet };
}
