import React, { useRef } from 'react';
import styled from '@emotion/styled';

// import { useHoverDetector } from '../../../hooks';

type TableCellImageProps = {
  imageUrl: string;
  srcUrl: string;
};

// type StyledImageEnlargerContainerProps = {
//   showImageEnlarger: boolean;
// };

export default function TableCellImage({
  imageUrl,
  srcUrl,
}: TableCellImageProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  // const hover = useHoverDetector(wrapperRef);

  return (
    <section>
      <StyledImageContainer ref={wrapperRef}>
        <img
          alt="row"
          src={imageUrl}
          onClick={() => window.open(srcUrl)}
          width="81"
          height="81"
        />
      </StyledImageContainer>
      {/* <StyledImageEnlargerContainer showImageEnlarger={hover}>
        <Image alt="viewer image" src={imageUrl} width="180" height="180" />
      </StyledImageEnlargerContainer> */}
    </section>
  );
}

const StyledImageContainer = styled.div`
  background-color: var(--society-light-turquoise);
  border-radius: 4px;
  cursor: pointer;
  width: 81px;
  height: 81px;
`;

// const StyledImageEnlargerContainer = styled.div<StyledImageEnlargerContainerProps>`
//   display: ${({ showImageEnlarger }) => (showImageEnlarger ? 'block' : 'none')};
//   position: absolute;
//   top: 2px;
//   left: 164px;
//   z-index: 1;

//   img {
//     border-radius: 12px;
//   }
// `;
