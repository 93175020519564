import React from 'react';
import styled from '@emotion/styled';

import { TableCell, TableCellCheckbox } from './table-cell';
import { TableCellHeader } from './table-cell/TableCell';

export type TableRowCellHeader = TableCellHeader & {
  cellHandler: (rowId: string) => void;
};
export type TableRowPropsRow = { [key: string]: string };
type TableRowProps = {
  customStyles: { [key: string]: string };
  headers: TableRowCellHeader[];
  row: TableRowPropsRow;
  selectedRows: string[];
  setSelectedRows: (selectedRows: string[]) => void;
  showCheckboxCell: boolean;
};

type StyledTableRowProps = {
  selected: boolean;
  tableRowHeight: string;
};

export default function TableRow({
  customStyles: { tableRowHeight },
  headers,
  row,
  selectedRows,
  setSelectedRows,
  showCheckboxCell = true,
}: TableRowProps) {
  const tableCells = headers.map((header, index) => (
    <TableCell
      key={`${header.column}-${index}`}
      header={header}
      row={row}
      cellHandler={header.cellHandler}
    />
  ));

  const selected = selectedRows.includes(row.id.toString());

  const checkboxCell = showCheckboxCell && (
    <TableCellCheckbox
      id={row.id}
      selected={selected}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
    />
  );

  return (
    <StyledTableRow
      key={row.id}
      selected={selected}
      tableRowHeight={tableRowHeight}
    >
      {checkboxCell}
      {tableCells}
    </StyledTableRow>
  );
}

const StyledTableRow = styled.tr<StyledTableRowProps>`
  background-color: ${({ selected }) =>
    selected
      ? 'var(--society-dark-charcoal-dust)'
      : 'var(--society-dark-charcoal)'};
  border-radius: 4px;
  border: 1px solid transparent;
  height: ${({ tableRowHeight = '52px' }) => tableRowHeight};
  position: relative;
  text-align: left;

  :last-child,
  :nth-last-of-type(2) {
    section {
      div:nth-of-type(2) {
        top: -30px;
      }
    }
  }
`;
