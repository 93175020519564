/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { GetWalletGenericResponse } from '../types/SocietyLoyaltyWallet';
import { StatusResult } from './useFetchStatus';
import fetch, { Response } from 'node-fetch';
import dotenv from 'dotenv';

// 0 is off, 1 is on, 2 is unknown
export type ServiceStatusCode = 0 | 1 | 2;

export async function getStatus(): Promise<StatusResult> {
  //   console.log('AppService.getStatus: start');

  let appStatus: ServiceStatusCode = 2;
  let moralisStatus: ServiceStatusCode = 2;
  try {
    if (!process.env.REACT_APP_SOCIETY_APP_TEST_URL) {
      throw Error('SOCIETY_APP_TEST_URL not set!');
    }
    const appUrl = process.env.REACT_APP_SOCIETY_APP_TEST_URL;
    const appRes = await fetch(appUrl, {
      // headers: { 'Access-Control-Allow-Origin': '*' },
    });
    appStatus = appRes.status === 200 ? 1 : 0;
    console.log('AppService.getStatus: ', { appUrl, status: appRes.status });
  } catch (err) {
    const error = err as { name: string; message: string };
    console.error(
      `AppService.getStatus: society url '${
        process.env.REACT_APP_SOCIETY_APP_TEST_URL || 'undefined'
      }' error %o %o`,
      error.name,
      error.message,
    );
    appStatus = 0;
  }

  try {
    if (!process.env.REACT_APP_SOCIETY_MORALIS_TEST_URL) {
      throw Error('MORALIS_TEST_URL not set!');
    }
    const moralisUrl = process.env.REACT_APP_SOCIETY_MORALIS_TEST_URL;
    const moralisRes = await fetch(moralisUrl);
    moralisStatus = moralisRes.status === 200 ? 1 : 0;
    console.log('AppService.getStatus: ', {
      moralisUrl,
      status: moralisRes.status,
    });
  } catch (err) {
    const error = err as { name: string; message: string };
    console.error(
      `AppService.getStatus: moralis url '${
        process.env.REACT_APP_SOCIETY_MORALIS_TEST_URL || 'undefined'
      }' error %o %o`,
      error.name,
      error.message,
    );
    moralisStatus = 0;
  }

  const res: StatusResult = {
    appStatus,
    moralisStatus,
  };
  return res;
}

export async function getWallet(address: string) {
  if (!process.env.REACT_APP_BASE_APP_URL) {
    throw Error('BASE_APP_URL not set!');
  }
  const walletUrl = `${process.env.REACT_APP_BASE_APP_URL}/api/p/loyalty/get-wallets-status/${address}`;
  // console.log(`AppService.getWallet: %o`, walletUrl);
  try {
    const walletRes = await fetch(walletUrl);
    // console.log(`AppService.getWallet: %o %o`, walletRes, walletUrl);
    if (walletRes.ok) {
      return (await walletRes.json()) as GetWalletGenericResponse;
    } else {
      console.error(`AppService.getWallet: bad %o %o`, walletRes, walletUrl);
    }
  } catch (err) {
    const error = err as { name: string; message: string };
    console.error(
      'AppService.getWallet: walletRes error %o %o %o',
      error.name,
      error.message,
      walletUrl,
    );
  }
  // console.error(`AppService.getWallet: query failed %o`, walletUrl);
  return { error: 'Society wallet query failed!' };
}

export async function getMoralisWalletNfts(address: string) {
  try {
    if (!process.env.REACT_APP_BASE_APP_URL) {
      throw Error('BASE_APP_URL not set!');
    }
    const moralisWalletUrl = `${process.env.REACT_APP_BASE_APP_URL}/api/p/loyalty/get-moralis-wallets-status/${address}`;
    // console.log(`AppService.getMoralisWalletNfts: ${moralisWalletUrl}`);
    const walletRes = await fetch(moralisWalletUrl);
    // console.log(
    //   `AppService.getMoralisWalletNfts: ${moralisWalletUrl} %o`,
    //   walletRes,
    // );
    if (walletRes.ok) {
      return (await walletRes.json()) as GetWalletGenericResponse;
    } else {
      console.warn(
        `AppService.getMoralisWalletNfts: ${moralisWalletUrl} bad %o`,
        walletRes,
      );
    }
  } catch (err) {
    const error = err as { name: string; message: string };
    console.error(
      'AppService.getMoralisWalletNfts: walletRes error %o %o',
      error.name,
      error.message,
    );
  }
  return { error: 'Moralis wallet query failed!' };
}
