import React, { useState } from 'react';
import { GetWalletGenericResponse } from '../types/SocietyLoyaltyWallet';
import { getWallet } from './getStatus';

export default function useFetchSocietyWallet() {
  const [societyWallet, setWallet] = useState<GetWalletGenericResponse>();

  function fetchSocietyWallet(address: string) {
    getWallet(address)
      .then((walletResp) => {
        // console.log('useFetchWallet.fetchSocietyWallet: walletResp', { walletResp });
        if (!(walletResp as { error: string }).error) {
          try {
            setWallet(walletResp as GetWalletGenericResponse);
          } catch (err) {
            const error = err as { name: string; message: string };
            console.error(
              'useFetchWallet.fetchSocietyWallet: res.json() parse error %o %o %o',
              error.name,
              error.message,
              walletResp,
            );
            setWallet(undefined);
          }
        } else {
          console.log(
            'useFetchSocietyWallet.fetchSocietyWallet: res not ok %o',
            walletResp,
          );
          setWallet(undefined);
        }
      })
      .catch((err) => {
        console.error('useFetchWallet.fetchSocietyWallet: error', err);
        // return Promise.reject(error);
      });
  }

  return { societyWallet, fetchSocietyWallet };
}
