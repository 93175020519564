import React from 'react';
type TableCellWalletAddressProps = {
  address: string;
  firstN?: number | undefined;
  lastN?: number | undefined;
};

export default function TableCellWalletAddress({
  address,
  firstN = 12,
  lastN = -4,
}: TableCellWalletAddressProps) {
  return (
    <span>
      {address.slice(0, firstN)}...{address.slice(lastN)}
    </span>
  );
}
