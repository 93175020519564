import { WalletData } from '../types/WalletData';
import {
  GetWalletGeneric,
  GetWalletGenericResponse,
} from '../types/SocietyLoyaltyWallet';

// const findDeepProperty = (data: SocietyLoyaltyWalletBad[], prop: string) => {};

export const parseWalletData = (
  walletAddress: string,
  resp: GetWalletGenericResponse,
  currentPage: number,
  nftsPerPage: number,
  selected?: string,
): WalletData => {
  const wallets = resp?.wallets;
  const lowerWalletAddr = walletAddress.toLowerCase();
  try {
    const searchedWallet = wallets.find((wallet) => {
      return lowerWalletAddr === wallet.address.toLowerCase();
    });

    const associatedWallets = wallets.filter(
      (wallet) => walletAddress !== wallet.address,
    );

    let searchedWalletLength = 0;
    const projectList: string[] = [];
    const filteredBySelectWallet =
      searchedWallet?.nfts?.filter((nft) => {
        if (!projectList.includes(nft.name)) {
          projectList.push(nft.name);
        }
        if (nft.name === selected) {
          searchedWalletLength = searchedWalletLength + 1;
          return true;
        }
        if (selected === 'All') {
          searchedWalletLength = searchedWalletLength + 1;
          return true;
        } else {
          return false;
        }
      }) || [];

    const mappedProjectList = projectList.map((project, index) => ({
      name: project,
      id: index,
    }));

    //TODO: srcUrl as thumbnail is bad code right now
    const filteredSearchedWallet =
      filteredBySelectWallet.filter((nft, index) => {
        if (
          currentPage * nftsPerPage - nftsPerPage <= index &&
          index < currentPage * nftsPerPage
        ) {
          return true;
        } else {
          return false;
        }
      }) || [];
    const nftData = filteredSearchedWallet.map((nft) => {
      const parsedMetadata = JSON.parse(nft.metadata) as {
        image: string;
        name: string;
      };
      let metadataImage = parsedMetadata.image;
      const metadataName = parsedMetadata.name;
      let srcUrl = metadataImage;
      if (metadataImage.includes('ipfs')) {
        metadataImage = metadataImage.substr(7);
        srcUrl = 'https://ipfs.io/ipfs/' + metadataImage;
        metadataImage =
          'https://society-public-assets.s3.amazonaws.com/oewBase.jpg';
      }
      return {
        id: nft.name,
        name: metadataName,
        imageUrl: metadataImage || '',
        srcUrl: srcUrl,
        token_address: nft.token_address,
        points: nft.value,
        image: nft.image,
        metadata: nft.metadata,
      };
    });
    const walletsWithID = resp.wallets.filter(
      (wallet: GetWalletGeneric) =>
        wallet.user?.discordId != null && wallet.user?.discordId !== '',
    );
    const discordId = walletsWithID[0]?.user?.discordId || 'None';

    return {
      mappedProjectList,
      searchedWalletLength,
      associatedWallets: associatedWallets || [],
      discordId,
      nfts: nftData,
      totalPoints: searchedWallet?.points || 0,
      voteNumber: searchedWallet?.extraVotes || 0,
      walletAddress,
    };
  } catch (err) {
    // console.log(err);
  }
  //fallthrough
  return new WalletData();
};
