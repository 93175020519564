import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';

import { useOutsideClickDetector } from '../../../hooks';
import ContextMenu, { ContextMenuPropsItem } from '../../ContextMenu';

type TableCellContextMenuProps = {
  contextMenuItems: ContextMenuPropsItem[];
  // {
  //   iconUrl: string;
  //   text: string;
  //   handler: (entityId: string | number) => void;
  //   config: { danger?: boolean; success?: boolean };
  // }[];
  config?: { bottom?: string };
  rowId: string;
};

type StyledKebabMenuContainerProps = {
  highlight: boolean;
};

export default function TableCellContextMenu({
  contextMenuItems,
  config,
  rowId,
}: TableCellContextMenuProps) {
  const [showMenu, setShowMenu] = useState(false);
  const wrapperRef = useRef<HTMLElement>(null);
  const [clickedOutside, setClickedOutside] =
    useOutsideClickDetector(wrapperRef);

  function handleKebabMenuClick() {
    setShowMenu(!showMenu);
  }

  if (showMenu && clickedOutside) {
    setShowMenu(false);
    setClickedOutside(false);
  }

  return (
    <section ref={showMenu ? wrapperRef : null}>
      <StyledKebabMenuContainer
        highlight={showMenu}
        onClick={handleKebabMenuClick}
      >
        <img
          alt="edit or delete menu"
          src="/kebab-menu.png"
          width="16px"
          height="16px"
        />
      </StyledKebabMenuContainer>
      <ContextMenu
        entityId={rowId}
        items={contextMenuItems}
        show={showMenu}
        setShowMenu={setShowMenu}
        config={config || {}}
      />
    </section>
  );
}

const StyledKebabMenuContainer = styled.div<StyledKebabMenuContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ highlight }) =>
    highlight ? 'var(--society-dark-midnight-1)' : 'transparent'};
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
`;
