import React from 'react';
import styled from '@emotion/styled';

export type ContextMenuPropsItem = {
  icon?: boolean;
  iconUrl: string;
  text: string;
  handler: (entityId: string | number) => void;
  config: { danger?: boolean; success?: boolean };
  type?: string;
  separatorAbove?: boolean;
  disabled?: boolean;
};
type ContextMenuProps = {
  config: { bottom?: string };
  entityId?: string;
  items: ContextMenuPropsItem[];
  show: boolean;
  setShowMenu: (show: boolean) => void;
};
type ContextMenuListItemProps = {
  config: { danger?: boolean; success?: boolean };
  entityId: string | number;
  handler: (entityId: string | number) => void;
  disabled: boolean;
  iconUrl: string;
  setShowMenu: (show: boolean) => void;
  text: string;
  icon: boolean;
};

type StyledContextMenuContainerProps = {
  config: { bottom?: string };
  show: boolean;
};

type StyledContextMenuListItemContainerType = {
  config?: { danger?: boolean; success?: boolean };
  disabled?: boolean;
};

function ContextMenuListItem({
  config = { danger: false },
  handler,
  iconUrl,
  entityId,
  setShowMenu,
  text,
  icon,
  disabled,
}: ContextMenuListItemProps) {
  function handleClick() {
    handler(entityId);
    setShowMenu(false);
  }

  return (
    <StyledContextMenuListItemContainer
      className={disabled ? '' : 'active'}
      disabled={disabled}
      config={config}
      onClick={(e: any) => (disabled ? console.log('Disabled') : handleClick())}
    >
      {icon ? icon : <img alt={text} src={iconUrl} height="16" width="16" />}
      <span>{text}</span>
    </StyledContextMenuListItemContainer>
  );
}

export default function ContextMenu({
  entityId,
  items = [],
  show = true,
  setShowMenu,
  config = { bottom: '-10px' },
}: ContextMenuProps) {
  const contextMenuListItems = items.map(
    (
      { iconUrl, text, handler, config, type, separatorAbove, icon, disabled },
      index,
    ) => {
      if (type === 'header') {
        return (
          <StyledContextMenuHeader key={`${text}-${index}`}>
            {separatorAbove ? <div className="separator"></div> : ''}
            <div>{text}</div>
          </StyledContextMenuHeader>
        );
      }
      return (
        <ContextMenuListItem
          config={config}
          handler={handler}
          icon={icon || false}
          disabled={disabled || false}
          iconUrl={iconUrl}
          key={`${text}-${index}`}
          entityId={entityId || ''}
          setShowMenu={setShowMenu}
          text={text}
        />
      );
    },
  );

  return (
    <StyledContextMenuContainer show={show} config={config}>
      <ul>{contextMenuListItems}</ul>
    </StyledContextMenuContainer>
  );
}

const StyledContextMenuContainer = styled.div<StyledContextMenuContainerProps>`
  border: 1px solid var(--society-border-1);
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 85%);
  border-radius: 4px;
  background-color: var(--society-dark-midnight-1);
  color: var(--society-gray-3);
  display: ${({ show }) => (show ? 'block' : 'none')};
  padding: 10px 0px;
  position: absolute;
  right: 16px;
  bottom: ${({ config: { bottom } }) => bottom};
  z-index: 1;
  width: 220px;
  letter-spacing: 0.01em;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-size: 15px;
      display: flex;
      align-items: center;

      svg {
        font-size: 13px;
        width: 18px !important;
      }
    }

    span {
      margin-left: 8px;
    }
  }
`;

const StyledContextMenuListItemContainer = styled.li<StyledContextMenuListItemContainerType>`
  color: ${({ config }) =>
    config?.danger
      ? 'var(--society-danger)'
      : config?.success
      ? 'var(--society-light-turquoise)'
      : 'var(--society-highlight-white)'};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  padding: 7px 24px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &.active:hover {
    background-color: rgba(120, 120, 120, 0.2);
  }
`;

const StyledContextMenuHeader = styled.div`
  color: rgba(160, 160, 160, 1);
  font-size: 13px;
  padding: 2px 24px;
  .separator {
    margin-top: 10px;
    border-bottom: 1px rgba(80, 80, 80, 1) solid;
    margin-bottom: 18px;
  }
`;
