import styled from '@emotion/styled';
import React from 'react';
import { StyledTitleContainer } from './styled';

type StatusContainerProps = {
  children: React.ReactNode;
  title: string;
};

export default function StatusContainer({
  children = [],
  title = 'STATUS',
}: StatusContainerProps) {
  return (
    <StyledStatusContainer>
      <StyledInfoContainer>
        <StyledTitleContainer>
          <h2>{title}</h2>
        </StyledTitleContainer>
        {children}
      </StyledInfoContainer>
    </StyledStatusContainer>
  );
}

const StyledStatusContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledSocietyLogoHeader = styled.header`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const StyledInfoContainer = styled.section`
  padding: 10px 14px;
  position: relative;

  width: 300px;

  div:first-of-type {
    padding: 10px 0;
    width: 170px;
  }

  color: white;
`;
