import styled from '@emotion/styled';

export const StyledTitleContainer = styled.div`
  display: block;
  align-items: center;
  width: 250px;

  h2 {
    font-size: var(--font-size-small);
    margin-right: 8px;
  }

  img {
    text-align: center;
    margin: 0 auto;
  }
`;

export const StyledRowContainer = styled.section`
  align-items: center;
  display: flex;
  width: 300px;
  position: relative;
`;

export const StyledRowLabel = styled.div`
  display: block;
  align-items: center;

  h2 {
    font-size: var(--font-size-small);
    margin-right: 8px;
  }
`;
