import React from 'react';
type TableCellStatusProps = {
  iconUrl: string;
  status: string;
};

export default function TableCellStatus({
  iconUrl,
  status,
}: TableCellStatusProps) {
  return (
    <>
      <div>
        {/* <Image alt="status icon" src={iconUrl} width="10" height="10" /> */}
        <img alt="status icon" src="/cancel-x.svg" width="10" height="10" />
        <span>{status}</span>
      </div>
      <style>
        {`
          span {
            margin-left: 10px;
          }
        `}
      </style>
    </>
  );
}
