import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { parseWalletData } from '../../utils/parseWalletData';
import { WalletData } from '../../types/WalletData';
import { DataTable } from '../data-table';
import { SearchTarget } from '../../types/SearchFns';
import { GetWalletGenericResponse } from '../../types/SocietyLoyaltyWallet';
import useFetchSocietyWallet from '../../fetchers/useFetchSocietyWallet';
import useFetchMoralisNfts from '../../fetchers/useFetchMoralisNfts';
import { toast } from 'react-toastify';

const SearchSection = (): React.ReactElement => {
  const { societyWallet, fetchSocietyWallet } = useFetchSocietyWallet();
  const { moralisWallet, fetchMoralisWallet } = useFetchMoralisNfts();

  const runSearchFn = (searchInput: string) => {
    // console.log('SearchSection.runSearchFn: searchInput', { searchInput: searchInput });
    if (!searchInput || searchInput.length < 20) {
      toast.error('Invalid address');
      return false;
    }
    fetchSocietyWallet(searchInput);
    fetchMoralisWallet(searchInput);

    return true;
  };

  const [selectedTab, setSelectedTab] = useState<SearchTarget>('Society');
  const [societyWalletData, setSocietyWalletData] = useState<WalletData>();
  const [moralisWalletData, setMoralisWalletData] = useState<WalletData>();
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [isSocietySearching, setIsSocietySearching] = useState<boolean>(false);
  const [isMoralisSearching, setIsMoralisSearching] = useState<boolean>(false);
  const [societyCurrentPage, setSocietyCurrentPage] = useState<number>(1);
  const [moralisCurrentPage, setMoralisCurrentPage] = useState<number>(1);
  // const [nftsPerPage, setNftsPerPage] = useState<number>(5);
  const nftsPerPage = 5;
  const [societyNftNumber, setSocietyNftNumber] = useState<number>(1);
  const [moralisNftNumber, setMoralisNftNumber] = useState<number>(1);
  const [error, setError] = useState<string | null>('');
  const [selected, setSelected] = useState<string>('All');
  const [moralisSelected, setMoralisSelected] = useState<string>('All');
  const [projectList, setProjectList] = useState<
    { name: string; id: number }[]
  >([{ name: 'All', id: 0 }]);
  const [moralisProjectList, setMoralisProjectList] = useState<
    { name: string; id: number }[]
  >([{ name: 'All', id: 0 }]);
  // let searchingFalseTimeoutBU: NodeJS.Timeout;

  const onSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      performSearch(searchInputValue);
    } catch (e: any) {
      console.error(`an error occurred when looking for wallet data: `, e);
      setError((e as { message: string }).message);
    }
  };

  const projectOptionElements = projectList.map((project, index) => (
    <option key={index} value={project.name}>
      {project.name}
    </option>
  ));

  const moralisProjectOptionElements = moralisProjectList.map(
    (project, index) => (
      <option key={index} value={project.name}>
        {project.name}
      </option>
    ),
  );

  function performSearch(address: string) {
    if (!address) {
      return;
    }

    setIsSocietySearching(true);
    setIsMoralisSearching(true);
    setSocietyWalletData(undefined);
    setMoralisWalletData(undefined);
    setError(null);

    if (!runSearchFn(address)) {
      setIsSocietySearching(false);
      setIsMoralisSearching(false);
      return;
    }

    //in case query fails and isSearching doesn't get set to false
    setTimeout(() => {
      setIsSocietySearching(false);
      setIsMoralisSearching(false);
    }, 20000);
  }

  function handleDataChange(
    data: GetWalletGenericResponse | undefined,
    dataSetterFunction: React.Dispatch<
      React.SetStateAction<WalletData | undefined>
    >,
  ) {
    if (!data) {
      // toast.info('No data for this wallet address');
      console.warn('No data!');
    } else if (data.error || (data.statusCode && data.statusCode !== 200)) {
      console.warn('Data error %o', data);
      const dataType =
        dataSetterFunction === setSocietyWalletData ? 'Society' : 'Moralis';
      toast.warn(`${dataType}: ` + (data.error ?? 'Wallet not found'));
    } else {
      const dataType =
        dataSetterFunction === setSocietyWalletData ? 'Society' : 'Moralis';
      if (dataType === 'Society') {
        const parsedWalletData = parseWalletData(
          searchInputValue,
          data,
          societyCurrentPage,
          nftsPerPage,
          selected,
        );
        console.log('parsedWalletData %o', parsedWalletData);
        const dataType =
          dataSetterFunction === setSocietyWalletData ? 'Society' : 'Moralis';
        if (dataType === 'Society') {
          setSocietyNftNumber(parsedWalletData.searchedWalletLength || 0);
        }
        if (dataType === 'Moralis') {
          setMoralisNftNumber(parsedWalletData.searchedWalletLength || 0);
        }
        setProjectList(parsedWalletData.mappedProjectList || []);
        dataSetterFunction(parsedWalletData);
      }
      if (dataType === 'Moralis') {
        const parsedWalletData = parseWalletData(
          searchInputValue,
          data,
          moralisCurrentPage,
          nftsPerPage,
          moralisSelected,
        );
        const dataType =
          dataSetterFunction === setSocietyWalletData ? 'Society' : 'Moralis';
        if (dataType === 'Society') {
          setSocietyNftNumber(parsedWalletData.searchedWalletLength || 0);
        }
        if (dataType === 'Moralis') {
          setMoralisNftNumber(parsedWalletData.searchedWalletLength || 0);
        }
        setMoralisProjectList(parsedWalletData.mappedProjectList || []);
        dataSetterFunction(parsedWalletData);
        window.history.pushState(null, '', `${parsedWalletData.walletAddress}`);
      }
    }
  }

  useEffect(() => {
    if (window.location.pathname !== '/') {
      const address = window.location.pathname.substring(1);
      setSearchInputValue(address);
      try {
        performSearch(address);
      } catch (e: any) {
        console.error(`an error occurred when looking for wallet data: `, e);
        setError((e as { message: string }).message);
      }
    }
  }, []);

  useEffect(() => {
    setSocietyCurrentPage(1);
  }, [selected]);

  useEffect(() => {
    setMoralisCurrentPage(1);
  }, [moralisSelected]);

  useEffect(() => {
    // console.log('SearchSection: society wallet changed %o', societyWallet);
    setIsSocietySearching(false);
    handleDataChange(societyWallet, setSocietyWalletData);
  }, [societyWallet, societyCurrentPage, selected]);

  useEffect(() => {
    // console.log('SearchSection: moralis wallet changed %o', moralisWallet);
    console.log(moralisSelected);
    setIsMoralisSearching(false);
    handleDataChange(moralisWallet, setMoralisWalletData);
  }, [moralisWallet, moralisCurrentPage, moralisSelected]);

  const moralisHeaders = [
    { display: 'NFT Image', column: 'imageUrl', cellType: 'image' },
    { display: 'Collection Name', column: 'id' },
    { display: 'NFT Name', column: 'name' },
    { display: 'Contract Address', column: 'token_address' },
  ];
  const societyHeaders = [
    ...moralisHeaders,
    { display: 'Points', column: 'points' },
  ];

  return (
    <StyledSearchBarSection>
      <StyledSearchHeader>
        <StyledTabContainer>
          <StyledSearchTab
            currentTab={selectedTab}
            onClick={() => setSelectedTab('Society')}
            role="tab"
            tabName="Society"
          >
            Society
          </StyledSearchTab>
          <StyledSearchTab
            currentTab={selectedTab}
            onClick={() => setSelectedTab('Moralis')}
            role="tab"
            tabName="Moralis"
          >
            Moralis
          </StyledSearchTab>
        </StyledTabContainer>
        <StyledSearchBarContainer>
          <form onSubmit={onSubmit}>
            <StyledSearchBarInput
              disabled={isSocietySearching}
              value={searchInputValue}
              placeholder="Search for Wallet Address"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchInputValue(e?.target?.value)
              }
            />
            <StyledSearchBarButton disabled={isSocietySearching}>
              Search
            </StyledSearchBarButton>
          </form>
        </StyledSearchBarContainer>
      </StyledSearchHeader>

      {error && <ErrorMessageText>{error}</ErrorMessageText>}

      {selectedTab === 'Society' && isSocietySearching && (
        <h2>Loading Society...</h2>
      )}

      {selectedTab === 'Society' && societyWalletData != null && (
        <>
          <StyledFilterContainer>
            <span> Filter by Collection:</span>
            <StyledFilterDropdown>
              <select
                value={selected}
                onChange={(e) => setSelected(e.target.value)}
              >
                <option id={'0'} value={'All'}>
                  All
                </option>
                {projectOptionElements}
              </select>
            </StyledFilterDropdown>
          </StyledFilterContainer>
          <div>
            <StyledAggregateContainer>
              <StyledAggregateDiv>
                <StyledAggregateHeader>Discord Id</StyledAggregateHeader>
                <div>{societyWalletData.discordId}</div>
              </StyledAggregateDiv>
              <StyledAggregateDiv>
                <StyledAggregateHeader>Total Points</StyledAggregateHeader>
                <div>{societyWalletData.totalPoints}</div>
              </StyledAggregateDiv>
              <StyledAggregateDiv>
                <StyledAggregateHeader>Total NFTs</StyledAggregateHeader>
                <div>{societyNftNumber}</div>
              </StyledAggregateDiv>
              <StyledAggregateDiv>
                <StyledAggregateHeader>Total Entries</StyledAggregateHeader>
                <div>{societyWalletData.voteNumber}</div>
              </StyledAggregateDiv>
            </StyledAggregateContainer>
            <DataTable
              headers={societyHeaders}
              rows={societyWalletData.nfts}
              showCheckboxColumn={false}
              selectedRows={[]}
              setSelectedRows={() => []}
            />
          </div>
          <PaginationFooter>
            <StyledSearchBarButton
              onClick={() => {
                societyCurrentPage > 1
                  ? setSocietyCurrentPage(societyCurrentPage - 1)
                  : setSocietyCurrentPage(societyCurrentPage);
              }}
            >
              Previous Page
            </StyledSearchBarButton>
            <span>{`Page ${societyCurrentPage} of ${
              societyNftNumber % nftsPerPage !== 0
                ? Math.floor(societyNftNumber / nftsPerPage) + 1
                : societyNftNumber / nftsPerPage
            }`}</span>
            <StyledSearchBarButton
              onClick={() => {
                societyNftNumber % nftsPerPage !== 0
                  ? societyCurrentPage <
                    Math.floor(societyNftNumber / nftsPerPage) + 1
                    ? setSocietyCurrentPage(societyCurrentPage + 1)
                    : setSocietyCurrentPage(societyCurrentPage)
                  : societyCurrentPage < societyNftNumber / nftsPerPage
                  ? setSocietyCurrentPage(societyCurrentPage + 1)
                  : setSocietyCurrentPage(societyCurrentPage);
              }}
            >
              Next Page
            </StyledSearchBarButton>
          </PaginationFooter>
        </>
      )}

      {selectedTab === 'Moralis' && isMoralisSearching && (
        <h2>Loading Moralis...</h2>
      )}

      {selectedTab === 'Moralis' && moralisWalletData != null && (
        <>
          <StyledFilterContainer>
            <span> Filter by Collection:</span>
            <StyledFilterDropdown>
              <select
                value={moralisSelected}
                onChange={(e) => setMoralisSelected(e.target.value)}
              >
                <option id={'0'} value={'All'}>
                  All
                </option>
                {moralisProjectOptionElements}
              </select>
            </StyledFilterDropdown>
          </StyledFilterContainer>
          <div>
            <StyledAggregateContainer>
              <StyledAggregateDiv>
                <StyledAggregateHeader>Total NFTs</StyledAggregateHeader>
                <div>{moralisNftNumber}</div>
              </StyledAggregateDiv>
            </StyledAggregateContainer>
            <DataTable
              headers={moralisHeaders}
              rows={moralisWalletData.nfts}
              showCheckboxColumn={false}
              selectedRows={[]}
              setSelectedRows={() => []}
            />
          </div>
          <PaginationFooter>
            <StyledSearchBarButton
              onClick={() => {
                moralisCurrentPage > 1
                  ? setMoralisCurrentPage(moralisCurrentPage - 1)
                  : setMoralisCurrentPage(moralisCurrentPage);
              }}
            >
              Previous Page
            </StyledSearchBarButton>
            <span>{`Page ${moralisCurrentPage} of ${
              moralisNftNumber % nftsPerPage !== 0
                ? Math.floor(moralisNftNumber / nftsPerPage) + 1
                : moralisNftNumber / nftsPerPage
            }`}</span>
            <StyledSearchBarButton
              onClick={() => {
                moralisNftNumber % nftsPerPage !== 0
                  ? moralisCurrentPage <
                    Math.floor(moralisNftNumber / nftsPerPage) + 1
                    ? setMoralisCurrentPage(moralisCurrentPage + 1)
                    : setMoralisCurrentPage(moralisCurrentPage)
                  : moralisCurrentPage < moralisNftNumber / nftsPerPage
                  ? setMoralisCurrentPage(moralisCurrentPage + 1)
                  : setMoralisCurrentPage(moralisCurrentPage);
              }}
            >
              Next Page
            </StyledSearchBarButton>
          </PaginationFooter>
        </>
      )}
    </StyledSearchBarSection>
  );
};

const StyledFilterDropdown = styled.div`
  margin: 0px 15px;
`;

const StyledFilterContainer = styled.div`
  display: flex;
  justify-content: left;
  margin: 10px;
`;

const StyledAggregateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

const PaginationFooter = styled.div`
  padding-top: 10px;
  width: 44.3rem;
  display: flex;
  justify-content: space-evenly;
`;

const StyledAggregateDiv = styled.div`
  padding: 0 0.5rem;
`;

const StyledAggregateHeader = styled.div`
  margin-bottom: 0.2rem;
`;

export const StyledSearchBarSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;
  width: 33.3rem;
`;

const StyledSearchBarButton = styled.button`
  height: 1.6rem;
  margin: 1px 0 0 0.5rem;
  padding: 5px;
`;

const StyledSearchBarContainer = styled.div`
  form {
    width: 28rem;
  }
`;

const StyledSearchBarInput = styled.input`
  height: 1.3rem;
  width: 22rem;
`;

const StyledSearchHeader = styled.div`
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: space-between;
`;

const StyledSearchTab = styled.a<{ currentTab: string; tabName: string }>`
  align-items: center;

  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  width: 5rem;

  ${({ currentTab, tabName }) =>
    currentTab === tabName &&
    `
    background-color: var(--society-dark-turquoise);
    border: 1px solid whitesmoke;
    box-sizing: border-box;
    `}
`;

const StyledTabContainer = styled.div`
  align-items: center;
  display: flex;
`;

// const StyledWalletResultLine = styled.p`
//   margin: 0.1rem 0 0.5rem 1rem;
// `;

// const StyledWalletNft = styled.p`
//   display: flex;
//   flex-direction: column;
//   margin: 1rem 0;
// `;

// const StyledWalletNftLine = styled.p`
//   margin: 0.1rem 0 0.1rem 1rem;
// `;

const ErrorMessageText = styled.h2`
  color: #cd3030;
`;

export default SearchSection;
