import React from 'react';

type TableCellLinkProps = {
  text: string;
  cellHandler: (rowId: string) => void;
  rowId: string;
};

export default function TableCellLink({
  text,
  cellHandler,
  rowId,
}: TableCellLinkProps) {
  function handleTableLinkButtonClick() {
    cellHandler(rowId);
  }

  return (
    <>
      <button onClick={handleTableLinkButtonClick}>{text}</button>
      <style>{`
        button {
          background-color: transparent;
          text-decoration: underline;
        }
      `}</style>
    </>
  );
}
