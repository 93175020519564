import { GetWalletGeneric, GetWalletGenericNft } from './SocietyLoyaltyWallet';

export class WalletData {
  mappedProjectList?: { name: string; id: number }[];
  searchedWalletLength?: number;
  associatedWallets?: GetWalletGeneric[];
  discordId?: string | null;
  nfts!: GetWalletGenericNft[];
  totalPoints?: number;
  voteNumber?: number;
  walletAddress!: string;
  error?: string;
}
