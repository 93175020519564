import React from 'react';
import styled from '@emotion/styled';

type TableCellTruncatedTextProps = {
  text: string;
};
export default function TableCellTruncatedText({
  text,
}: TableCellTruncatedTextProps) {
  return <StyledTruncatedText>{text}</StyledTruncatedText>;
}

const StyledTruncatedText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 242px;
`;
