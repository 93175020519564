import React, { useEffect, useState } from 'react';
import { StyledRowContainer, StyledRowLabel } from './styled';
import { ServiceStatusCode } from '../fetchers/getStatus';

type StatusContainerProps = {
  label: string;
  value: ServiceStatusCode | null;
};
export default function StatusRow({
  label = 'Some Status',
  value = null,
}: StatusContainerProps) {
  const [statusValue, setStatusValue] = useState(
    'https://upload.wikimedia.org/wikipedia/commons/2/27/Throbber_allbackgrounds_eightbar.gif',
  );
  useEffect(() => {
    const loading =
      'https://upload.wikimedia.org/wikipedia/commons/2/27/Throbber_allbackgrounds_eightbar.gif';
    const greenCheck =
      'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Green_Checkmark_Circle.svg/640px-Green_Checkmark_Circle.svg.png';
    const redX =
      'https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/White_X_in_red_background.svg/2048px-White_X_in_red_background.svg.png';
    setStatusValue(value != null ? (value === 1 ? greenCheck : redX) : loading);
  }, [value]);

  return (
    <StyledRowContainer>
      <StyledRowLabel>{label}</StyledRowLabel>
      <div style={{ width: '70px', textAlign: 'right' }}>
        <img src={statusValue} style={{ height: '24px', width: '24px' }} />
      </div>
    </StyledRowContainer>
  );
}
