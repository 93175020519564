import React, { useState } from 'react';
import styled from '@emotion/styled';

import TableHeaderIcon from './TableHeaderIcon';
import { TableRowPropsRow } from './TableRow';

export type TableHeader = {
  column: string;
  display: string;
  headerIconUrl: string;
  headerType: string;
};
type TableHeadersProps = {
  headers: TableHeader[];
  rows: TableRowPropsRow[];
  selectedRows: string[];
  setSelectedRows: (selectedRows: string[]) => void;
  showCheckboxColumn: boolean;
};

type StyledTableHeadersProps = {
  textAlign?: string;
};

export default function TableHeaders({
  headers,
  rows,
  selectedRows,
  setSelectedRows,
  showCheckboxColumn,
}: TableHeadersProps) {
  const [checkedAll, setCheckedAll] = useState(false);
  function handleInputChange() {
    setCheckedAll(!checkedAll);
    setSelectedRows(!checkedAll ? rows?.map((row) => row.id.toString()) : []);
  }

  const checkboxColumn = showCheckboxColumn && (
    <StyledTableHeaders>
      <input
        type="checkbox"
        onChange={handleInputChange}
        checked={selectedRows.length > 0}
      />
    </StyledTableHeaders>
  );

  return (
    <thead>
      <tr>
        {checkboxColumn}
        {headers.map(
          ({ column, display, headerIconUrl, headerType }, index) => {
            return headerType === 'icon' ? (
              <StyledTableHeaders key={`${column}-${index}`} textAlign="center">
                <TableHeaderIcon alt={display} src={headerIconUrl} />
              </StyledTableHeaders>
            ) : (
              <StyledTableHeaders key={`${column}-${index}`}>
                {display}
              </StyledTableHeaders>
            );
          },
        )}
      </tr>
    </thead>
  );
}

const StyledTableHeaders = styled.th<StyledTableHeadersProps>`
  background-color: var(--society-dark-charcoal-dust);
  color: white;
  font-weight: var(--font-weight-bold);
  height: 32px;
  padding: 0 10px;
  text-align: ${({ textAlign = 'left' }) => textAlign};

  input[type='checkbox'] {
    cursor: pointer;
  }
`;
