import { useEffect, useState } from 'react';
export default function useHoverDetector(ref: React.RefObject<HTMLElement>) {
  const [hover, setHover] = useState(false);
  useEffect(() => {
    function handleHover(event: MouseEvent) {
      setHover(
        !!ref.current &&
          !!event.target &&
          ref.current.contains(event.target as Node),
      );
    }
    document.addEventListener('mouseover', handleHover);
    return () => {
      document.removeEventListener('mouseover', handleHover);
    };
  }, [ref]);

  return hover;
}
