import React from 'react';
import styled from '@emotion/styled';

import TableCellAvatar from './TableCellAvatar';
import TableCellBolded from './TableCellBolded';
import TableCellContextMenu from './TableCellContextMenu';
import TableCellImage from './TableCellImage';
import TableCellLink from './TableCellLink';
import TableCellNameWithAvatar from './TableCellNameWithAvatar';
import TableCellStatus from './TableCellStatus';
import TableCellTruncatedText from './TableCellTruncatedText';
import TableCellWalletAddress from './TableCellWalletAddress';
import { TableRowPropsRow } from '../TableRow';
import ContextMenu, { ContextMenuPropsItem } from '../../ContextMenu';
import { TableHeader } from '../TableHeaders';

export type TableCellHeaderConfig = {
  // config: {
  status?: Record<string, { iconUrl?: string; name?: string }>;
  width?: any;
  contextMenuItems: ContextMenuPropsItem[];
  bottom: any;
  // };
};
export type TableCellHeader = TableHeader & {
  cellType: string;
  config?: TableCellHeaderConfig;
  columns: string[];
};
type TableCellProps = {
  row: TableRowPropsRow;
  header: TableCellHeader;
  // {
  // cellType: string;
  // column: string;
  // config: TableCellHeaderConfig;
  // {
  //   status?: Record<string, { iconUrl?: string; name?: string }>;
  //   width?: any;
  //   //   contextMenuItems?: {
  //   //     iconUrl: string;
  //   //     text: string;
  //   //     handler: Function;
  //   //     config: Record<string, unknown>;
  //   //   }[];
  //   contextMenuItems: ContextMenuPropsItem[];
  //   bottom: any;
  // };
  // columns: string[];
  // };
  cellHandler: (rowId: string) => void;
};

type StyledTableCellProps = {
  cellType?: string;
};

function formatTableCell({
  row,
  header: { cellType, config, column, columns = [] },
  cellHandler,
}: TableCellProps) {
  let cellValue = row[column];
  if (column?.includes('.')) {
    const split = column.split('.');
    cellValue = split.reduce((a, b) => `${a || ''}[${b}]`, '');
  }

  switch (cellType) {
    case 'avatar':
      return <TableCellAvatar active={row.active !== 'false'} />;
    case 'bolded':
      return <TableCellBolded text={cellValue} config={config} />;
    case 'clickable':
      return (
        <TableCellLink
          text={cellValue}
          cellHandler={cellHandler}
          rowId={row.id}
        />
      );
    case 'concatenated':
      return `${row[columns[0]]} - ${row[columns[1]]}`;
    case 'id':
      return `${cellValue.slice(0, 6)}...${cellValue.slice(-6)}`;
    case 'image':
      return <TableCellImage imageUrl={row.imageUrl} srcUrl={row.srcUrl} />;
    case 'kebabMenu':
      let menuItems = config?.contextMenuItems || [];
      if (cellValue === '2') {
        menuItems =
          config?.contextMenuItems?.filter(
            (item) => !item.text.match(/assign/i),
          ) || [];
      }
      return (
        <TableCellContextMenu
          contextMenuItems={menuItems || []}
          config={config}
          rowId={row.id}
        />
      );
    case 'nameWithAvatar':
      return (
        <TableCellNameWithAvatar name={cellValue} avatarUrl={row.avatarUrl} />
      );
    case 'status':
      return (
        <TableCellStatus
          iconUrl={config?.status?.[cellValue]?.iconUrl || ''}
          status={config?.status?.[cellValue]?.name || ''}
        />
      );
      break;
    case 'truncatedText':
      return <TableCellTruncatedText text={cellValue} />;
    case 'walletAddress':
      return <TableCellWalletAddress address={cellValue} />;
    default:
      return cellValue;
  }
}
export default function TableCell({
  header,
  row,
  cellHandler,
}: TableCellProps) {
  return (
    <StyledTableCell cellType={header.cellType}>
      {formatTableCell({ row, header, cellHandler })}
    </StyledTableCell>
  );
}

const StyledTableCell = styled.td<StyledTableCellProps>`
  width: ${({ cellType }) => (cellType === 'truncatedText' ? '242px' : 'auto')};
`;
