import React from 'react';
import styled from '@emotion/styled';

type TableCellNameWithAvatarProps = {
  name: string;
  avatarUrl: string;
};

export default function TableCellNameWithAvatar({
  name,
  avatarUrl,
}: TableCellNameWithAvatarProps) {
  return (
    <StyledContainer>
      <img src={avatarUrl} alt={`${name} user`} width="26" height="26" />
      <span>{name}</span>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
`;
