import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import useFetchStatus from '../fetchers/useFetchStatus';
import {
  StyledRowContainer,
  StyledRowLabel,
  StyledTitleContainer,
} from './styled';
import { ServiceStatusCode } from '../fetchers/getStatus';
import { default as StatusContainer } from './statusContainer';
import { default as StatusRow } from './statusRow';
import SearchSection from './SearchSection';
import { Constants } from '../shared/constants';
import { /*toast,*/ ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function StatusPage() {
  const { status, fetchStatus } = useFetchStatus();
  const [appStatus, setAppStatus] = useState<ServiceStatusCode | null>(null);
  const [moralisStatus, setMoralisStatus] = useState<ServiceStatusCode | null>(
    null,
  );

  useEffect(() => {
    console.log('StatusPage: running fetchStatus on load');
    void (async () => {
      await fetchStatus();
    })();
    setInterval(() => {
      console.log('StatusPage: running fetchStatus on timeout');
      void fetchStatus().then();
    }, 600000);
    // return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (status) {
      setAppStatus(status.appStatus);
      setMoralisStatus(status.moralisStatus);
    }
  }, [status]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className={'toastContainer'}
      />
      <StyledStatusPageContainer>
        <StyledSocietyLogoHeader>
          <StyledTitleContainer>
            <img
              alt="society logo"
              src="https://society-public-assets.s3.amazonaws.com/society-logo-s.svg"
              className="shrine-logo"
              width="60"
              height="60"
            />
          </StyledTitleContainer>
        </StyledSocietyLogoHeader>

        <StyledStatusContentContainer>
          <StatusContainer title="Status">
            <StatusRow label="App Status" value={appStatus} />
            <StatusRow label="Moralis Status" value={moralisStatus} />
            <StyledRowContainer>
              <StyledRowLabel>{'Version'}</StyledRowLabel>
              <div>{process.env.REACT_APP_SOCIETY_VERSION}</div>
            </StyledRowContainer>
            <StyledRowContainer>
              <StyledReportBugLink
                href={`${Constants.reportBugAddress}`}
                target="_blank"
              >
                Submit a Bug Report
              </StyledReportBugLink>
            </StyledRowContainer>
          </StatusContainer>
          <SearchSection />
        </StyledStatusContentContainer>
      </StyledStatusPageContainer>
    </>
  );
}

const StyledStatusContentContainer = styled.div`
  display: flex;
  width: 85vw;
`;

const StyledStatusPageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledSocietyLogoHeader = styled.header`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const StyledReportBugLink = styled.a`
  background-color: var(--society-dark-turquoise);
  color: var(--text-color);
  cursor: pointer;
  font-size: 13px;
  margin: 0.8rem 0;
  padding: 5px;
  text-decoration: none;
  &:hover {
    color: darkslategray;
  }
`;
