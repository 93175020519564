import React from 'react';
import styled from '@emotion/styled';

import TableBody from './TableBody';
import TableHeaders from './TableHeaders';
import {
  // SocietyLoyaltyWalletBad,
  GetWalletGenericNft,
} from '../../types/SocietyLoyaltyWallet';

type DataTableProps = {
  customStyles?: { tableRowStyles?: { [key: string]: string } };
  headers: any[];
  rows: GetWalletGenericNft[];
  selectedRows: string[];
  setSelectedRows: (selectedRows: string[]) => void;
  showCheckboxColumn?: boolean;
};

export default function DataTable({
  customStyles,
  headers,
  rows = [],
  selectedRows,
  setSelectedRows,
  showCheckboxColumn = false,
}: DataTableProps) {
  const stringValueRows = rows.map((row) => ({
    ...row,
    value: row.value?.toString() || '',
    votes: row.votes?.toString() || '',
  }));
  return (
    <StyledTable>
      <TableHeaders
        headers={headers}
        rows={stringValueRows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        showCheckboxColumn={showCheckboxColumn}
      />
      <TableBody
        customStyles={customStyles}
        headers={headers}
        rows={stringValueRows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        showCheckboxColumn={showCheckboxColumn}
      />
    </StyledTable>
  );
}

const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0px;
  /* overflow: hidden; */
  width: 100%;

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid var(--society-light-grey);
    border-radius: 4px;
    height: 20px;
    width: 20px;
    text-align: center;
    margin: 16px;
  }

  input[type='checkbox']:checked {
    border: 1px solid var(--society-light-turquoise);
    background-color: var(--society-light-turquoise);
  }

  input[type='checkbox']:checked::after {
    content: '\\2713';
    font-size: var(--font-size-body);
  }

  td {
    padding: 0 10px;
    img {
      max-width: 80px;
    }
  }
`;
