import { useEffect, useState } from 'react';
import { getStatus } from './getStatus';
import { ServiceStatusCode } from './getStatus';

export type StatusResult = {
  appStatus: ServiceStatusCode;
  moralisStatus: ServiceStatusCode;
};
export default function useFetchStatus() {
  const [status, setStatus] = useState<StatusResult>();
  async function fetchStatus() {
    // console.log('useFetchStatus: running fetchStatus actual');
    try {
      const statusRes = await getStatus();
      setStatus(statusRes);
      // console.log('useFetchStatus.fetchStatus: statusObj', { statusObj: statusObj });
    } catch (err) {
      console.error('useFetchStatus.fetchStatus: error %o', err);
    }
  }

  return { status, fetchStatus };
}
