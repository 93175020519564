import React from 'react';
type TableCellAvatarProps = {
  active: boolean;
};

export default function TableCellAvatar({ active }: TableCellAvatarProps) {
  return (
    <img
      src={`/identity/avatar-${active ? 'active' : 'inactive'}.png`}
      alt={`${active ? 'active' : 'inactive'} user`}
      width="32px"
      height="32px"
    />
  );
}
