import styled from '@emotion/styled';
import React from 'react';

type TableCellCheckboxProps = {
  id: string;
  selected: boolean;
  selectedRows: string[];
  setSelectedRows: (selectedRows: string[]) => void;
};

export default function TableCellCheckbox({
  id,
  selected,
  selectedRows,
  setSelectedRows,
}: TableCellCheckboxProps) {
  function handleInputChange({
    target: {
      type,
      dataset: { rowId },
    },
  }: React.ChangeEvent<HTMLInputElement>): void {
    if (!rowId) {
      setSelectedRows([]);
    } else {
      setSelectedRows(
        selectedRows.includes(rowId || '')
          ? selectedRows.filter((selectedRowId) => selectedRowId !== rowId)
          : [...selectedRows, rowId],
      );
    }
  }
  return (
    <>
      <StyledTableCellCheckboxContainer>
        <input
          type="checkbox"
          data-row-id={id}
          onChange={handleInputChange}
          checked={selected}
        />
      </StyledTableCellCheckboxContainer>
    </>
  );
}

const StyledTableCellCheckboxContainer = styled.td`
  width: 30px;

  input[type='checkbox'] {
    cursor: pointer;
  }
`;
