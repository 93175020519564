import React from 'react';
import styled from '@emotion/styled';

type TableCellBoldedProps = {
  text: string;
  config?: { width?: string };
};

type StyledContainerProps = {
  config: { width?: string };
};

export default function TableCellBolded({
  text,
  config = { width: '40px' },
}: TableCellBoldedProps) {
  return <StyledContainer config={config}>{text}</StyledContainer>;
}

const StyledContainer = styled.div<StyledContainerProps>`
  font-weight: var(--font-weight-bolder);
  width: ${({ config: { width } }) => width};
`;
